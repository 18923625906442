import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import Contact from '@components/contact';
import Hero from '@src/components/hero';
import Layout from '@components/layout';
import MarkdownContent from '@components/markdown-content/MarkdownContent';

const AnvandarVillkor = ({ data }) => {
  const headLine = data.contentfulPage.headLine;
  const headerTitle = data.contentfulPage.pageHeader;
  const pageHeaderSubtitle =
    data?.contentfulPage?.pageHeaderSubtitle?.pageHeaderSubtitle;
  const headerText =
    data.contentfulPage.pageHeaderDescription.pageHeaderDescription;

  const contactHeader = data.contentfulPage.pageContactHeader;
  const { bodyText } = data.contentfulPage.pageContent[0].bodyText;

  return (
    <Layout transparentNavbar>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/allmanna-villkor', name: 'Allmänna Villkor' },
          { path: '/allmanna-villkor/rocker-pay', name: 'Rocker Pay' },
        ]}
        title={headerTitle}
        subtitleMarkdown={headerText}
        extraMarkdown={pageHeaderSubtitle}
      />
      <MarkdownContent markdown={bodyText} />
      <Contact headerText={contactHeader} isLargeContact={true} hideFAQ />
    </Layout>
  );
};

export default AnvandarVillkor;

AnvandarVillkor.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query RockerPayFakturaVillkorQuery {
    contentfulPage(
      pageUrl: { eq: "/allmanna-villkor/rocker-pay/faktura-blanco" }
    ) {
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      pageContactHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          bodyText {
            bodyText
            childMarkdownRemark {
              html
            }
          }
        }
      }
      headLine
    }
  }
`;
